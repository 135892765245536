<template>
  <TailwindComponentsCommandPalette
    :items="permittedCollections(filteredTagsColl)"
    :listIcon="listIcon"
    :listType="'collection'"
    :isLoading="isLoading"
    :placeholder="placeholder"
    :label="label"
    :helpText="helpText"
    @search="getFilteredTags"
    @selected="option => (selectedColl = option)"
    :emitSelection="emitSelection"
    :selectable="selectable"
  >
  </TailwindComponentsCommandPalette>
</template>

<script>
export default {
  setup() {
    const { userProfile } = useKeycloak()
    const collectionsApi = useCollections()
    return {
      collectionsApi,
      userProfile,
    }
  },
  data() {
    return {
      tagsColl: [],
      collectionData: [],
      filteredTagsColl: [],
      selectedColl: null,
      awaitingSearch: false,
      prevText: '',
      timeout: null,
      listIcon: 'folder-multiple-outline',
      isLoading: false,
      isLoadInProgress: false,
    }
  },
  props: {
    helpText: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Search collections by title/ID',
    },
    label: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: '',
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    collId: {
      type: Number,
    },
    emitSelection: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    allowedRoles: {
      type: Array,
      default: () => [ROLE_OWNER],
    },
  },
  watch: {
    selectedColl: {
      handler(value) {
        if (value) {
          if (value.colId) {
            this.$emit('emitColl', value)
          } else {
            this.$emit('emitColl', { colId: value })
          }
        }
      },
      deep: true,
    },
    collId: {
      handler(value) {
        if (value) {
          this.loadCollection()
        }
      },
    },
  },
  async mounted() {
    if (this.collId) {
      this.loadCollection()
    } else {
      this.load()
    }
  },
  methods: {
    permittedCollections(colls) {
      return this.userProfile.IsAdmin
        ? colls
        : colls.filter(collection => this.allowedRoles.includes(collection.role))
    },
    async getFilteredTags(text) {
      clearTimeout(this.timeout)

      var self = this
      this.timeout = setTimeout(function () {
        self.getFilteredCollection(text)
      }, 500)
    },
    async getFilteredCollection(text) {
      this.isLoading = true
      let data
      try {
        data = await this.collectionsApi.filterCollections({
          offset: 0,
          limit: 100,
          filter: text,
        })
        // Check if load() is in progress, if yes, queue the result
        if (this.isLoadInProgress) {
          this.$once('loadCompleted', () => {
            this.collectionData = data.trpCollection.reverse()
            this.filteredTagsColl = this.collectionData
            this.isLoading = false
          })
        } else {
          this.collectionData = data.trpCollection.reverse()
          this.filteredTagsColl = this.collectionData
          this.isLoading = false
        }
      } catch (err) {
        this.isLoading = false
        return
      }
    },
    async loadCollection() {
      this.isLoading = true

      let data
      try {
        data = await this.collectionsApi.fetchCollectionMeta({
          collId: this.collId,
        })
        this.collectionData = data
        this.selectedColl = this.collectionData

        if (this.tagsColl.some(e => e.colId === this.collectionData.colId)) {
          return
        }
        this.tagsColl.push(this.collectionData)
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        return
      }
    },
    async load() {
      this.isLoading = true
      this.isLoadInProgress = true

      let data
      try {
        data = await this.collectionsApi.fetchCollections({
          offset: 0,
          limit: 100,
        })
        this.collectionData = data.trpCollection.reverse()
        this.filteredTagsColl = this.collectionData

        this.isLoading = false
        this.isLoadInProgress = false
        this.$emit('loadCompleted')
      } catch (err) {
        this.isLoading = false
        this.isLoadInProgress = false
        return
      }
    },
  },
}
</script>
